<template>
  <div class="container">
    <div class="banner">
      <img src="@/assets/images/muahohangnhat.png" alt="" />
    </div>
    <div class="changeRate">
      <div>
        <h3>Tỉ giá chuyển đổi JPY sang VNĐ</h3>
        <p>
          Tính đến 1 phút trước, 1 VNĐ -> {{ dataConverter.rateJPYtoVND }} JPY
        </p>
      </div>
      <div>
        <h3>
          1 JPY = <span>{{ dataConverter.rateShow }}</span> VNĐ
        </h3>
        <p>Cập nhật {{ dataConverter.timeShow }}</p>
      </div>
    </div>
    <el-row :gutter="16">
      <el-col :lg="12" :md="12" :sm="24">
        <div class="box-bill">
          <h3 class="title">Ước tính đơn hàng</h3>
          <h4>Nhập tổng tiền hàng JPY</h4>
          <div></div>
          <div class="navbar-frame-search">
            <div style="display: flex">
              <el-input
                class="navbar-mobile-input-search"
                v-model="moneyJPY"
                placeholder="Bạn muốn tìm sản phẩm gì?"
                @input="filterInput"
              >
              </el-input>
              <div class="unit">
                <div>
                  <img src="@/assets/images/japanflag.png" alt="japan flag" />
                </div>

                <span>JPY</span>
              </div>
            </div>
          </div>
          <div class="content-bill">
            <div class="line-box">
              <span class="money">
                <i class="fa-solid fa-xmark"></i>

                <h4>{{ rateShow }} JPY -> VNĐ</h4>
              </span>
              <span> Tỉ giá</span>
            </div>
            <div class="line-box">
              <span class="money">
                <i class="fa-solid fa-equals"></i>

                <h4>{{ new Intl.NumberFormat().format(totalVNĐ) }} VNĐ</h4>
              </span>
              <span> Tổng tiền hàng</span>
            </div>
            <div class="line-box">
              <span class="money">
                <i class="fa-solid fa-plus"></i>

                <h4>{{ new Intl.NumberFormat().format(feeOrder) }} VNĐ</h4>
              </span>
              <span> Phí mua hộ (3%)</span>
            </div>
            <div class="line-box">
              <span class="money">
                <i class="fa-solid fa-plus"></i>

                <h4>{{ new Intl.NumberFormat().format(feeShip) }} VNĐ</h4>
                /
                <el-input
                  placeholder="Cân"
                  size="mini"
                  v-model="inputKg"
                  class="input-kg"
                ></el-input>
                <h4>kg</h4>
              </span>
              <span> Phí vận chuyển</span>
            </div>
            <div class="line-box">
              <span class="money">
                <i class="fa-solid fa-equals"></i>

                <h4>{{ new Intl.NumberFormat().format(totalFee) }} VNĐ</h4>
              </span>
              <span> Tổng phí</span>
            </div>
            <div class="line"></div>
            <div class="line-box">
              <span class="money">
                <i class="fa-solid fa-equals"></i>

                <h4>{{ new Intl.NumberFormat().format(totalBillJPY) }} JPY</h4>
              </span>
              <span> Thành tiền</span>
            </div>

            <h4>Thành tiền VNĐ</h4>
            <span style="font-style: italic; font-size: 12px"
              >(chỉ mang tính chất tham khảo, liên hệ để được báo giá chi
              tiết)</span
            >
            <div class="navbar-frame-search">
              <div style="display: flex">
                <div class="moneyVND">
                  {{ new Intl.NumberFormat().format(totalBillVND) }}
                </div>
                <div class="unit">
                  <div>
                    <img
                      src="@/assets/images/vietnamflag.png"
                      alt="japan flag"
                    />
                  </div>

                  <span>VNĐ</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :lg="12" :md="12" :sm="24">
        <div class="box-request">
          <h3 class="title">Yêu cầu mua hộ</h3>

          <div class="cart-container-address">
            <div class="cart-address-title">
              <p class="mt-10 font-size-p">Họ tên</p>
              <el-input
                class="cart-input"
                placeholder="Nhập họ và tên (bắt buộc)"
                type="text"
                v-model="orderInfo.CustomerName"
              ></el-input>
            </div>
            <div class="cart-address-title">
              <p class="mt-10 font-size-p">Số điện thoại</p>
              <el-input
                class="cart-input"
                placeholder="Nhập số điện thoại (bắt buộc)"
                v-model="orderInfo.CustomerPhone"
              ></el-input>
            </div>
            <div class="cart-address-title">
              <p class="mt-10 font-size-p">Email</p>
              <el-input
                class="cart-input"
                type="email"
                placeholder="Nhập email (nếu có)"
                v-model="orderInfo.CustomerEmail"
              ></el-input>
            </div>
            <div class="cart-address-title">
              <p class="mt-10 font-size-p">Tỉnh/Tp</p>

              <el-select
                class="cart-select"
                v-model="orderInfo.City"
                filterable
                clearable=""
                no-data-text="Chưa có thông tin"
                @change="getDistrictLst"
                placeholder="Nhập tỉnh/tp (bắt buộc)"
              >
                <el-option
                  v-for="item in cityLst"
                  :key="item.City"
                  :label="item.City"
                  :value="item.City"
                >
                </el-option>
              </el-select>
            </div>
            <div class="cart-address-title">
              <p class="mt-10 font-size-p">Quận/Huyện</p>
              <el-select
                class="cart-select"
                v-model="orderInfo.District"
                filterable
                no-data-text="Chưa có thông tin"
                @change="getCommuneLst"
                placeholder="Nhập quận/huyện (bắt buộc)"
              >
                <el-option
                  v-for="item in districtLst"
                  :key="item.District"
                  :label="item.District"
                  :value="item.District"
                >
                </el-option>
              </el-select>
            </div>
            <div class="cart-address-title">
              <p class="mt-10 font-size-p">Phường/Xã</p>
              <el-select
                class="cart-select"
                v-model="orderInfo.Commune"
                filterable
                no-data-text="Chưa có thông tin"
                placeholder="Nhập phường/xã (bắt buộc)"
              >
                <el-option
                  v-for="item in communeLst"
                  :key="item.Commune"
                  :label="item.Commune"
                  :value="item.Commune"
                >
                </el-option>
              </el-select>
            </div>
            <div class="cart-address-title">
              <p class="mt-10 font-size-p">Địa chỉ</p>
              <el-input
                class="cart-input"
                placeholder="Nhập số nhà/ tên đường (bắt buộc)"
                v-model="orderInfo.Address"
              ></el-input>
            </div>
            <div class="cart-address-title">
              <p class="mt-10 font-size-p">Link mua hàng</p>
              <el-input
                class="cart-input"
                placeholder="Điển link mua hàng"
                v-model="orderInfo.LinkOrder"
              ></el-input>
            </div>
            <div class="cart-address-title">
              <p class="mt-10 font-size-p">Ghi chú</p>
              <el-input
                class="cart-input"
                placeholder="Nhập ghi chú"
                v-model="orderInfo.Note"
              ></el-input>
            </div>
            <div
              v-if="displayCaptcha"
              style="margin-top: 20px; display: flex; justify-content: center"
            >
              <span>
                <vue-recaptcha
                  sitekey="6Lee90MpAAAAABdb9qjJZCMHETHCjpfcDOU0Q0CQ"
                  ref="recaptcha"
                  @verify="onVerify"
                  @expired="onExpired"
                  :loadRecaptchaScript="true"
                ></vue-recaptcha>
              </span>
            </div>

            <button
              v-if="captcha"
              class="button-gradient confirm-bill"
              @click="createRequestOrder"
            >
              Hoàn tất yêu cầu
            </button>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { GetPublicConverter } from "@/api/converterApi.js";
import { formatDateDisplay } from "@/utils/getTime.js";
import {
  GetCity,
  GetCommuneByCityAndDistrict,
  GetDistrictByCity,
} from "@/api/productApi.js";
import { CreateRequestOrder } from "@/api/requestOrderApi.js";
import VueRecaptcha from "vue-recaptcha";

export default {
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      dataConverter: {},
      moneyJPY: 10000,
      orderInfo: {},
      cityLst: [],
      districtLst: [],
      communeLst: [],
      captcha: "",
      inputKg: 1,
      money1kg: 153000,
    };
  },
  computed: {
    displayCaptcha() {
      if (
        this.orderInfo.CustomerName &&
        this.orderInfo.CustomerPhone &&
        this.orderInfo.City &&
        this.orderInfo.District &&
        this.orderInfo.Commune &&
        this.orderInfo.Address &&
        this.orderInfo.LinkOrder
      ) {
        return true;
      } else {
        return false;
      }
    },
    rateShow() {
      return parseFloat(this.dataConverter.rateShow).toFixed(2) ?? 0;
    },
    totalVNĐ() {
      return parseInt(this.moneyJPY) * this.rateShow;
    },
    feeOrder() {
      return parseInt(this.totalVNĐ * 0.03);
    },
    feeShip() {
      return this.inputKg
        ? parseFloat(this.inputKg) * this.money1kg
        : this.money1kg;
    },
    totalFee() {
      return this.feeOrder + this.feeShip;
    },
    totalBillVND() {
      return parseInt(this.totalVNĐ + this.totalFee);
    },
    totalBillJPY() {
      return parseInt(this.totalBillVND / this.rateShow);
    },
  },
  methods: {
    filterInput() {
      // Lọc giá trị chỉ để lại số
      this.moneyJPY = this.moneyJPY.replace(/\D/g, "");
    },

    onVerify(response) {
      console.log(response);
      this.captcha = response;
    },
    onExpired() {
      this.captcha = null;
    },
    createRequestOrder() {
      if (
        this.orderInfo.CustomerName &&
        this.orderInfo.CustomerPhone &&
        this.orderInfo.City &&
        this.orderInfo.District &&
        this.orderInfo.Commune &&
        this.orderInfo.Address &&
        this.orderInfo.LinkOrder
      ) {
        if (this.captcha) {
          this.captcha = null;
          CreateRequestOrder({
            Data: this.orderInfo,
          })
            .then((res) => {
              if (res.RespCode == 0) {
                this.$notify({
                  title: "Yêu cầu mua hộ",
                  message:
                    "Yêu cầu của bạn đã được gửi tới bộ phận chăm sóc. Chúng tôi sẽ liên hệ với bạn qua số điện thoại",
                  type: "success",
                });
              }
            })
            .finally(() => {
              this.$refs.recaptcha.reset();
            });
        }
      } else {
        this.$notify({
          title: "Nhắc nhở",
          message: "Vui lòng nhập đầy đủ thông tin",
          type: "warning",
        });
      }
    },
    getCityLst() {
      GetCity({}).then((res) => {
        this.cityLst = res.Data;
      });
    },
    getDistrictLst() {
      GetDistrictByCity({ City: this.orderInfo.City }).then((res) => {
        this.districtLst = res.Data;
      });
    },
    getCommuneLst() {
      GetCommuneByCityAndDistrict({
        City: this.orderInfo.City,
        District: this.orderInfo.District,
      }).then((res) => {
        this.communeLst = res.Data;
      });
    },
    getPublicConverter() {
      GetPublicConverter().then((res) => {
        var data = res.Data.find((p) => p.CurrencyCode == "JPY");

        this.dataConverter = {
          ...data,
          rate: data.Sell + 5,
          rateShow: (parseFloat(data.Sell) + 5).toFixed(5),
          rateJPYtoVND: (1 / (parseFloat(data.Sell) + 5)).toFixed(8),
          timeShow: formatDateDisplay(res.UpdatedDate),
        };
      });
    },
  },
  created() {
    // this.$refs.recaptcha.reset();
    this.getPublicConverter();
    this.getCityLst();
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: block;
}
.banner {
  margin-top: 16px;
  img {
    width: 100%;
    border-radius: 16px;
  }
}
.changeRate {
  background: #fff;
  margin-top: 16px;
  border-radius: 16px;
  width: calc(100% - 64px);
  display: flex;
  justify-content: space-between;
  padding: 32px;
  h3 {
    font-size: 22px;
    span {
      color: #008000;
    }
  }
  p {
    margin-top: 16px;
    color: #454745;
    font-size: 18px;
  }
}
.box-bill {
  background: #fff;
  padding: 32px;
  margin-top: 16px;
  border-radius: 16px;
  .title {
    text-align: center;
    text-transform: uppercase;
    color: #c3262e;
    margin-bottom: 16px;
  }
  .content-bill {
    padding: 24px 0;

    .line-box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      color: #0e0f0c !important;
      line-height: 24px;
      .money {
        display: flex;

        i {
          font-size: 8px;
          line-height: 20px;
          margin-right: 16px;
          color: #163300;
          background: #ececec;
          padding: 0 6px;
          border-radius: 50px;
          height: 20px;
        }
        h4 {
          white-space: nowrap;
        }
      }
    }
  }
  .navbar-frame-search {
    position: relative;
    width: 100%;
    margin: auto;
    margin-top: 16px;
    z-index: 310;
    border: 2px solid #008000;
    height: 72px;
    border-radius: 10px;
    line-height: 72px;
    .unit {
      display: flex;
      margin: auto 0;
      line-height: 85px;
      img {
        height: 24px;
        width: 24px;
        border-radius: 50%;
        object-fit: cover;
        border: 1px solid #454745;
      }
      span {
        line-height: 72px;
        margin: 0 24px 0 8px;
        font-size: 20px;
      }
    }
    .navbar-mobile-input-search {
      padding: 0 24px;
      font-size: 28px !important;
      color: #000 !important;
    }
    .moneyVND {
      width: 100%;
      padding-left: 24px;
      font-size: 28px;
      font-weight: 600;
    }
  }
  .line {
    height: 1px;
    width: 100%;
    background: #ccc;
    margin-bottom: 16px;
  }
}
.box-request {
  background: #fff;
  padding: 32px;
  margin-top: 16px;
  border-radius: 16px;
  .title {
    text-align: center;
    text-transform: uppercase;
    color: #c3262e;
  }
  .cart-container-address {
    background-color: #fff;
    margin-top: 14px;
    //   padding: 20px;
    //   width: calc(100% - 40px);
    border-radius: 8px;
    .cart-address-title {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      p {
        width: 200px;
      }
      .cart-select {
        width: 100%;
      }
    }
    .confirm-bill {
      margin: 12px 0 -16px 0;
      width: 100%;
      border-radius: 50px;
      height: 40px;
      font-size: 16px;
      color: #fff;
      border: none;
    }
  }
}
@media screen and (max-width: 678px) {
  .changeRate {
    margin-top: 16px;
    width: calc(100% - 32px);
    display: block;

    padding: 16px;
    padding-top: 8px;
    h3 {
      font-size: 18px;
      margin-top: 8px;
      span {
        color: #008000;
      }
    }
    p {
      margin-top: 8px;
      color: #454745;
      font-size: 15px;
    }
  }
}
</style>
<style>
.box-bill .el-input__inner {
  color: black;
  font-weight: 600;
}
.input-kg .el-input__inner {
  width: 60px;
  border: none;
  border-bottom: 1px solid #008000;
  border-radius: 0;
  text-align: center;
  padding: 0 0px 0 5px;
  margin-left: 1px;
  margin-top: -8px;
}
.input-kg .el-input__icon {
  height: 30px;
  color: #008000;
}
.input-kg .el-input__suffix {
  right: 0;
}
</style>
